import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import styles from './content-txt-img.module.scss'


export default ({direction,txt,imgmobile,imgdesktop})=> {
    
    return (
      <StaticQuery
      query={graphql`
        query {
          mobileImage1: file(relativePath: { eq: "home/illustration-diffs.png" }) {
            childImageSharp {
              fluid(maxWidth: 750, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          desktopImage1: file(relativePath: { eq: "home/illustration-diffs.png" }) {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mobileImage2: file(relativePath: { eq: "home/illustration-employer.png" }) {
            childImageSharp {
              fluid(maxWidth: 750, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          desktopImage2: file(relativePath: { eq: "home/illustration-employer.png" }) {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <div className={`${styles.contenttxtimg} ${direction} container`}>
          <div>
            <Img fluid={[
              data[imgmobile].childImageSharp.fluid,
              {
                ...data[imgdesktop].childImageSharp.fluid,
                media: `(min-width: 768px)`,
              },
            ]}/>
          </div>
          <div className={`${styles.contenttxtimgInfo} adjust-mg`}>
            <h2>{txt.title}</h2>  
            <ul className={styles.contenttxtimgInfoList}>
              {txt.items.map(function(item, index){
                return <li className={styles.contenttxtimgInfoListItem} key={ index }>{item}</li>;
              })}
            </ul>
          </div>
        </div>
      )}
    />
  )   
}


