import React from 'react'
import BgImg from '../images/svg/bg-head.svg'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import styles from './hero.module.scss'

export default ({imgpage}) => {
	return(
		<StaticQuery
		query={graphql`
			query {
				homeImg: file(relativePath: { eq: "home/illustration-home.png" }) {
					childImageSharp {
						fluid(maxWidth: 750, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`}
		render={data => (
		<div className={styles.hero}>
			
			<div className={`${styles.heroContainer} container`}>
				<div className={styles.heroImgillusWrapper}>
					<Img className={styles.heroImgillusItem} fluid={[data[imgpage].childImageSharp.fluid]}/>
				</div>
				<div className={`${styles.heroDetails} container`}>
					<h1 className={styles.heroDetailsTitle}>La boutique informatique réinventée</h1>
					<p className={styles.heroDetailsSubtitle}>L'Atelier Info est une boutique dans le domaine des technologies. 
					Nous offrons un service de réparation de cellulaires et de tablettes, service informatique et 
					vente de matériel pour les entreprises et les particuliers. </p>
					<a href="/contact" className={`${styles.heroDetailsBtn} btn btn--secondary`}>Appelez-Nous</a>
				</div>
			</div>
			<BgImg className={styles.heroImg}/>
		</div>
		)}
	/>
	)
}