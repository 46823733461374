import React from 'react'
import { Helmet } from 'react-helmet'

import styles from './index.module.scss'

import Hero from '../components/hero'
import Layout from '../components/layout'
import ContentTxtImg from '../components/content-txt-img'

class RootIndex extends React.Component {

  render() {
    const siteTitle = "L'Atelier Info | Informatique et cellulaire"
    const siteDesc = "L'Atelier Info est une boutique dans le domaine des technologies. Nous offrons un service de réparation de cellulaires et de tablettes, service informatique et vente de matériel pour les entreprises et les particuliers."
    const content1 = {
      title:'Nos  différentiateurs',
      items:[
        'Support de proximité pour les entreprises du secteur; rapide, efficace, compétent ',
        'Prise en charge rapide des demandes',
        'Trouvez tout ce dont vous avez besoin pour votre cellulaire et votre tablette en un seul endroit',
        'La réparation de cellulaire en moins de 30 minutes est disponible'
      ]
    }
    const content2 = {
      title:'Employeur de choix ',
      items:[
        'L\'Atelier Info est un employeur dynamique qui offre un environnement de travail enrichissant. L\'Atelier Info encourage les échanges entre tous les membres de l’équipe afin de toujours faire ressortir les meilleures idées et les meilleurs pratiques. L\'Atelier Info favorise la formation continue afin que tous les membres de l’équipe puissent s’épanouir au travail.'
      ]
    }
    

    return (
      <Layout location={this.props.location}>
        <div className="page-wrapper" style={{ background: '#fff' }}>
        <Helmet title={siteTitle}>
            <meta name="description" content={siteDesc} />
        </Helmet>
          <Hero imgpage="homeImg"/>
          <div id="ctaBlock" className={`${styles.blockModule} block container`}>
            <h2>Appelez-nous !</h2>
            <p>Pour toute demande de prix, estimation, réparation cellulaire et informatique, service et vente de matériel informatique pour les entreprises ou les particuliers n'hesitez-pas nous sommes là pour vous !</p>
              <div className="blockBtnwrapper">
                <a href="/contact" className="blockBtnwrapperItem btn btn--primary">Appelez-nous</a>
              </div>
          </div>
          <div id="serviceblock" className={`${styles.blockModule} block container`}>
            <h2>Nos Services</h2>
            <p>L'Atelier Info est une boutique dans le domaine des technologies. 
              Nous offrons un service de réparation de cellulaires et de tablettes, 
              service informatique et vente de matériel pour les entreprises et les particuliers.</p>
              <div className="blockBtnwrapper">
                <a href="/informatique" className="blockBtnwrapperItem btn btn--primary">Informatique</a>
                <a href="/mobiles" className="blockBtnwrapperItem btn btn--primary">Mobiles</a>
              </div>
          </div>
          <ContentTxtImg direction={'right-flex'} txt={content1} imgmobile="mobileImage1" imgdesktop="desktopImage1"/>
          <ContentTxtImg direction={'left-flex'} txt={content2} imgmobile="mobileImage2" imgdesktop="desktopImage2"/>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

